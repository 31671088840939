import Clamp from 'react-multiline-clamp';
import DateFormat from '../../../utils/dateFormat';
import hexToRgba from 'hex-to-rgba';
import htmlParse from 'html-react-parser';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useCallback, useRef } from 'react';
import Skeleton from '@mui/material/Skeleton';
import SVG from '../../../components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  activityTypeWidthParam?: any,
  activityTypeBackgroundColorParam?: any,
  activityTypeColorParam?: any,
  isPropagated?: any;
}

const useStyles = createUseStyles((theme: any) => ({
  popupCard: {
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    boxShadow: (props: Props) => {
      if(props.isPropagated) return theme.shadows[4];
      else return theme.shadows[2];
    },
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0px',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timelineHeader: {
    padding: '1rem 0rem 0.5rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  detailInformation: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  activityType: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 8px',
    borderRadius: '10px 0 0 10px',
    position: 'absolute',
    right: '0',
    transition: 'right 0.25s',
    backgroundColor: (props: Props) => props.activityTypeBackgroundColorParam,
    color: (props: Props) => props.activityTypeColorParam,
    '& > span': {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px 4px',
      right: (props: Props) => '-' + (props.activityTypeWidthParam - 35).toString() + 'px',
      '& > span': {
        fontSize: '10px',
        opacity: '0',
        transition: 'opacity 0.25s',
      },
      '&.active': {
        right: '0',
        '& > span': {
          opacity: '1',
        },
      },
    },
  },
  date: {
    display: 'flex',
    alignItems: 'center',
    color: '#C2C4D9',
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    '& > svg': {
      width: '14px',
      height: '14px',
      marginRight: '4px',
    }
  },
  timelineContent: {
    padding: '0.5rem 30px 20px 30px',
  },
  title: {
    display: 'block',
    fontWeight: 'bold',
    color: theme.colors.grey[800],
    fontSize: '24px',
    maxWidth: '100%',
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
  },
  description: {
    display: 'block',
    color: theme.colors.grey[650],
    fontSize: '14px',
    marginTop: '24px',
    position: "relative",
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    marginBottom: '16px',
    width: '100%',  
    '& a': {
      color: theme.colors.primaryBlue[500],
    },
  },
  spinner: {
    '& svg': {
      width: '116px',
      height: '116px',
      [theme.breakpoints.down('sm')]: {
        width: '68px',
        height: '68px',
      },
      color: "#888FAE",
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }
  },
  timelineBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  showToggleWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '0.5rem',
    backgroundColor: theme.colors.white,
  },
  showToggle: {
    color: theme.colors.primaryBlue[500],
    cursor: "pointer",
    fontWeight: '600',
    textTransform: 'uppercase',
    transition: 'color 0.25s',
    '&:hover': {
      color: theme.colors.primaryBlue[600],
    },
  },
  preloadWrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  timelineInformation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '8px 30px 8px 30px',
    width: 'calc(100% - 60px)',
    '&:empty': {
      display: 'none',
    },
  },
  timelineButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '12px 30px 20px 30px',
    width: 'calc(100% - 60px)',
    gap: '8px',
    '& > button': {
      position: 'relative',
    },
  },
  timelineButton: {
    height: '30px',
    padding: '5px 12px',
    minWidth: 'unset',
    fontSize: '12px',
    '& > span': {
      '& > svg': {
        width: '20px',
        height: '20px',
      },
    },
  },
}));

type ItemsType = {
  popupData: any;
  className?: any;
  displaySkeleton?: boolean;
  disableMoreContent?: boolean;
  disableButtons?: boolean;
  disableButtonMarkAsViewed?: boolean;
  disableButtonToggleViewed?: boolean;
  disableButtonPostPone?: boolean;
  disableButtonMore?: boolean;
};

const PopupCard: React.FunctionComponent<ItemsType> = ({ popupData, className, displaySkeleton, disableMoreContent, disableButtons, disableButtonMarkAsViewed, disableButtonPostPone, disableButtonToggleViewed, disableButtonMore }) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const popupID = popupData.popupID;
  const configurationData = useAppSelector((state: any) => state.configuration);
  console.log(configurationData, popupData);
  const browserData = useAppSelector((state: any) => state.browser);
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);
  const activityTypeEl: any = useRef({clientWidth: 0});
  const activityTypes = useMemo(() => configurationData.configuration.activityTypes ? configurationData.configuration.activityTypes : [], [configurationData.configuration.activityTypes]);
  const activityType = useMemo(() => Object.keys(popupData).length !== 0 ? (activityTypes.filter((activity: any) => activity.activityTypeID === popupData.categoryID).length === 0 ? null : activityTypes.find((activity: any) => activity.activityTypeID === popupData.categoryID)) : null, [activityTypes, popupData]);
  const activityTypeBackgroundColor = useMemo(() => activityType ? hexToRgba(activityType.color, '0.2') : '', [activityType]);
  const activityTypeColor = useMemo(() => activityType ? activityType.color : '', [activityType]);

  const [state, setState] = useStates({
    isLoaded: false,
    activityTypeActive: false,
    activityTypeWidth: 0,
  });

  const classes = useStyles({
    activityTypeWidthParam: state.activityTypeWidth,
    activityTypeBackgroundColorParam: activityTypeBackgroundColor,
    activityTypeColorParam: activityTypeColor,
  });
    
  useEffect(() => {
    if(activityTypeEl && activityTypeEl.current) {
      const activityTypeCurrentWidth: any = activityTypeEl.current.clientWidth;
      setState("activityTypeWidth", activityTypeCurrentWidth);
      setState("activityTypeActive", false);
      setState("isLoaded", true);
    }  
  }, [state.isLoaded, browserData.width, languageData.language, setState], [state.isLoaded, browserData.width, languageData.language]);

  const handleActivityActive = useCallback(() => {
    setState("activityTypeActive", !state.activityTypeActive);
  }, [setState, state.activityTypeActive]);

  return (state.isLoaded && !displaySkeleton) ? (
    <div id={`popupCard_${popupID}`} className={`${classes.popupCard} ${className ? className : ''}`} data-cy={`popupCard${popupID}`}>
      <div className={classes.timelineHeader}>
        <div className={classes.detailInformation}>
          <div>
            <span className={classes.title} data-cy={isCypress() ? "popupCardTitle" : null}>
              {popupData.title}
            </span> 
            <span data-clarity-unmask="true" className={classes.date} data-cy={isCypress() ? "popupCardDate" : null}>
              <SVG src="clock"/>
              {DateFormat(popupData.date,"timeline", languageData, t)}
            </span>
          </div>
          {
            activityType ? (
              <div className={`${classes.activityType} ${state.activityTypeActive ? 'active' : null}`} onClick={handleActivityActive} ref={activityTypeEl} data-cy={isCypress() ? "popupCardActivityType" : null}>
                <span>{activityType.name}</span>
              </div>
            ) : null
          }      
        </div>
      </div>
      <div className={classes.timelineContent}>

        {
          (popupData.content && popupData.content !== "") ? (
            <span className={classes.description} data-cy={isCypress() ? "popupCardContent" : null}>
              <Clamp
                lines={4}
                maxLines={100}
                withToggle={!disableMoreContent}
                withTooltip={!disableMoreContent}
                showMoreElement={({ toggle }: any) => (
                  <div className={classes.showToggleWrapper}>
                    <span className={classes.showToggle} onClick={toggle}>
                      {t('show_more')}
                    </span>
                  </div>
                )}
                showLessElement={({ toggle }: any) => (
                  <div className={classes.showToggleWrapper}>
                    <span className={classes.showToggle} onClick={toggle}>
                      {t('show_less')}
                    </span>
                  </div>
                )}
              >
                {htmlParse(popupData.content)}
              </Clamp>
            </span>
          ) : null
        }
        {
          !disableButtons ? (
            <div className={classes.timelineButtons}>
              {
                !disableButtonMarkAsViewed ? (
                  <NormalButton className={classes.timelineButton} buttonType="primary" startIcon={<SVG src="eye"/>}>
                    {t('popup_mark_as_viewed')}
                  </NormalButton>
                ) : null
              }
              {
                !disableButtonToggleViewed ? (
                  <NormalButton className={classes.timelineButton} buttonType="secondary" startIcon={<SVG src={popupData.viewed ? "eye-slash" : "eye"}/>}>
                    {popupData.viewed ? t('popup_mark_as_unviewed') : t('popup_mark_as_viewed')}
                  </NormalButton>
                ) : null
              }
              {
                !disableButtonPostPone ? (
                  <NormalButton className={classes.timelineButton} buttonType={"secondary"} startIcon={<SVG src="clock"/>}>
                    {t('popup_postpone')}
                  </NormalButton>
                ) : null
              }
              {
                !disableButtonMore ? (
                  <NormalButton className={classes.timelineButton} buttonType={"secondary"} startIcon={<SVG src="news-outlined"/>}>
                    {t('popup_more')}
                  </NormalButton>
                ) : null
              }
            </div>
          ) : null
        } 
      </div>
    </div>
  ) : (
    <div className={`${classes.popupCard} ${className ? className : ''}`}>
      <div className={classes.timelineHeader}>
        <div className={classes.date}>
          <div className={classes.preloadWrapper}>
            <Skeleton variant="text" animation="wave" width={100} height={10}/>
          </div>
        </div>     
      </div>
      <div className={classes.timelineContent}>
        <div className={classes.title}>
          <div className={classes.preloadWrapper}>
            <Skeleton variant="text" animation="wave" width={150} height={20}/>
          </div>
        </div>
         <div className={classes.description}>
          <div className={classes.preloadWrapper}>
            <Skeleton variant="rectangular" animation="wave" width={350} height={50}/>
          </div>
        </div>      
      </div>
      <div className={classes.timelineButtons}>
        <Skeleton variant="rectangular" animation="wave" width={90} height={30}/>
      </div>
    </div>
  );
}

export default PopupCard;