import PopupModal from 'src/components/Modals/PopupModal';
import PropTypes from 'prop-types';
import React from 'react';
import { useAppSelector } from 'src/hooks/redux-hooks';

interface Props {
  children: any;
}

const PopupsController: React.FunctionComponent<Props> = (props:Props) => {

  const popupsData = useAppSelector((state: any) => state.popups);
  const popup = popupsData.popup;

  return (
    <>
      {
        popup !== null ? (
          <PopupModal/>
        ) : null
      }
      {props.children}
    </>
  );
};

PopupsController.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default PopupsController;