import Modal from '../../../utils/modal';
import PopupCard from 'src/components/Cards/PopupCard';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setPopup } from 'src/store/actions/popups.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';

const useStyles = createUseStyles(() => ({
  root: {
    width: "auto",
    maxWidth: '90vw',
    maxHeight: 'calc(100vh - 40px)',
    marginBottom: '40px',
  },
}));

const PopupModal: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const popupsData = useAppSelector((state: any) => state.popups);

  const popupData = popupsData.popup;
 
  const onCloseModal = () => {
    dispatch(setPopup(null));
  };
  
  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
      position='center-bottom'
    >
      <div className={classes.root} data-cy={isCypress() ? "popupModal" : null}>
        <PopupCard popupData={popupData} disableMoreContent={true} disableButtonToggleViewed={true}/>
      </div>
    </Modal>
  );
};

export default PopupModal;