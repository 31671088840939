import HttpService from '../http.service';

const listPopups = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for (const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.get(`popup${query ? `${query}`: ""}`, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' }, {});
};

const settingsPopup = (popupID: any, payload: any) => {
  return HttpService.put(`popup/settings/${popupID}`, payload, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' }, {});
};
export {
  listPopups,
  settingsPopup,
};