import CheckboxInput from 'src/components/Forms/CheckboxInput';
import Clamp from 'react-multiline-clamp';
import CloseButton from 'src/components/Buttons/CloseButton';
import DateFormat from 'src/utils/dateFormat';
import htmlParse from 'html-react-parser';
import moment from 'src/utils/moment';
import NormalButton from 'src/components/Buttons/NormalButton';
import NotFound from 'src/components/Layouts/NotFound';
import React from 'react';
import Sidebar from 'src/components/Layouts/Sidebar';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  newsPage: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    overflow: 'auto',
    padding: '24px 24px',
    flex: '0 0 auto',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '24px 0px',
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  wrapperColumn: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  buttons: {
    display: 'flex',
    gap: '8px',
    marginTop: '16px',
    borderWidth: '2px',
    borderStyle: 'dashed',
    borderColor: theme.colors.systemRed[500],
    backgroundColor: theme.colors.systemRed[300],
    padding: '16px',
  },
  newsPopup: {
    position: 'fixed',
    bottom: '96px',
    right: '96px',
    backgroundColor: theme.colors.white,
    borderRadius: '12px',
    boxShadow: theme.shadows[2],
    padding: '16px',
    minWidth: '300px',
    maxWidth: '500px',
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '& > span': {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      fontSize: '20px',
      fontWeight: '500',
      '& > span': {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.colors.grey[200],
        borderRadius: '100%',
        padding: '8px',
        '& > svg': {
          width: '24px',
          height: '24px',
        },
      },
    },
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    '& > p': {
      fontSize: '14px',
    },
  },
  news: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  newsCard: {
    position: 'relative',
    backgroundColor: theme.colors.white,
    borderRadius: '12px',
    boxShadow: theme.shadows[2],
    padding: '16px',
    minWidth: '300px',
    maxWidth: '800px',
    width: '800px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '& > span': {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      fontSize: '20px',
      fontWeight: '500',
      '& > span': {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.colors.grey[200],
        borderRadius: '100%',
        padding: '8px',
        '& > svg': {
          width: '24px',
          height: '24px',
        },
      },
    },
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    '& > p': {
      fontSize: '14px',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '8px',
    right: '8px',
  },
  checkbox: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    width: 'unset !important',
  },
  date: {
    display: 'flex',
    alignItems: 'center',
    color: '#C2C4D9',
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    '& > svg': {
      width: '14px',
      height: '14px',
      marginRight: '4px',
    }
  },
  category: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 8px',
    borderRadius: '10px 0 0 10px',
    position: 'absolute',
    right: '0',
    transition: 'right 0.25s',
    backgroundColor: theme.colors.primaryBlue[100],
    color: theme.colors.black,
    '& > span': {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const languageData = useAppSelector((state: any) => state.language);

  const [state, setState] = useStates({
    news: [],
  });

  const handleAddNew = () => {
    const newData = {
      popupID: 1,
      title: 'Testovací novinka 1',
      content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Nulla turpis magna, cursus sit amet, suscipit a, interdum id, felis. Fusce wisi. Nullam justo enim, consectetuer nec, ullamcorper ac, vestibulum in, elit. Fusce suscipit libero eget elit. Morbi leo mi, nonummy eget tristique non, rhoncus non leo. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Fusce dui leo, imperdiet in, aliquam sit amet, feugiat eu, orci. Integer pellentesque quam vel velit. Maecenas sollicitudin.<br><br>Nulla pulvinar eleifend sem. Nullam dapibus fermentum ipsum. Curabitur bibendum justo non orci. Fusce tellus odio, dapibus id fermentum quis, suscipit id erat. Quisque porta. Nam quis nulla. Curabitur sagittis hendrerit ante. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. In convallis. Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Curabitur sagittis hendrerit ante. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.',
      category: 'Novinka',
      date: moment(),
      viewed: false,
      priority: 1,
      visible: true,
    };
    const news = [...state.news, newData];
    setState("news", news);
  };

  const handleAddNews = () => {
    const newsData = [
      {
        popupID: 1,
        title: 'Testovací novinka 1',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Nulla turpis magna, cursus sit amet, suscipit a, interdum id, felis. Fusce wisi. Nullam justo enim, consectetuer nec, ullamcorper ac, vestibulum in, elit. Fusce suscipit libero eget elit. Morbi leo mi, nonummy eget tristique non, rhoncus non leo. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Fusce dui leo, imperdiet in, aliquam sit amet, feugiat eu, orci. Integer pellentesque quam vel velit. Maecenas sollicitudin.<br><br>Nulla pulvinar eleifend sem. Nullam dapibus fermentum ipsum. Curabitur bibendum justo non orci. Fusce tellus odio, dapibus id fermentum quis, suscipit id erat. Quisque porta. Nam quis nulla. Curabitur sagittis hendrerit ante. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. In convallis. Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Curabitur sagittis hendrerit ante. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.',
        category: 'Novinka',
        date: moment(),
        viewed: false,
        priority: 1,
        visible: false,
      },
      {
        popupID: 2,
        title: 'Testovací novinka 2',
        content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Nulla turpis magna, cursus sit amet, suscipit a, interdum id, felis. Fusce wisi. Nullam justo enim, consectetuer nec, ullamcorper ac, vestibulum in, elit. Fusce suscipit libero eget elit. Morbi leo mi, nonummy eget tristique non, rhoncus non leo. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Fusce dui leo, imperdiet in, aliquam sit amet, feugiat eu, orci. Integer pellentesque quam vel velit. Maecenas sollicitudin.<br><br>Nulla pulvinar eleifend sem. Nullam dapibus fermentum ipsum. Curabitur bibendum justo non orci. Fusce tellus odio, dapibus id fermentum quis, suscipit id erat. Quisque porta. Nam quis nulla. Curabitur sagittis hendrerit ante. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. In convallis. Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Curabitur sagittis hendrerit ante. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.',
        category: 'Školení',
        date: moment(),
        viewed: false,
        priority: 2,
        visible: true,
      }
    ];
    const news = [...state.news, ...newsData];
    setState("news", news);
  };

  const handleRemoveNews = () => {
    setState("news", []);
  };

  const handleMakeNewRead = (popupID: any) => {
    const updatedNews = state.news.map((item: any) => {
      if(item.popupID === popupID) {
        return {...item, viewed: true, visible: false};
      } else {
        return item;
      }
    });
    setState("news", updatedNews);
  };

  const isVisibleNew = state.news.filter((item: any) => item.visible).length === 1;
  const visibleNew = state.news.find((item: any) => item.visible);
  
  return (
    <div className={classes.newsPage}>
      <div className={classes.wrapper}>
        <div className={classes.wrapperColumn}>
          {
            state.news.length === 0 ? (
              <>
                <NotFound title={t('no_news')} text={t('no_news_found')}/>
                <div className={classes.buttons}>
                  <NormalButton onClick={handleAddNew}>
                    Vytvořit aktualitu
                  </NormalButton>
                  <NormalButton onClick={handleAddNews} buttonType='secondary'>
                    Vytvořit více aktualit
                  </NormalButton>
                </div>
              </>
            ) : (
              <>
              <div className={classes.news}>
                {
                  state.news.map((item: any, key: any) => (
                    <div className={classes.newsCard} key={`k_${key}`}>
                      <span>
                        <span>
                          <SVG src="news"/>
                        </span>
                        {item.title}
                      </span>
                      <CheckboxInput className={classes.checkbox} labelLeft={item.viewed ? "Přečteno" : "Nepřečteno"} checked={item.viewed} onClick={() => handleMakeNewRead(item.popupID)} disabled={item.viewed}/>
                      <div>
                        <span className={classes.date}>
                          <SVG src="clock"/>
                          {DateFormat(item.date, "timeline", languageData, t)}
                        </span>
                        <div className={classes.category}>
                          <span>{item.category}</span>
                        </div>
                      </div>
                      <p>{htmlParse(item.content)}</p>
                    </div>
                  ))
                }
              </div>
              <div className={classes.buttons}>
                <NormalButton onClick={handleRemoveNews}>
                  Smazat aktuality
                </NormalButton>
              </div>
              </>
            )
          }
          {
            isVisibleNew ? (
              <div className={classes.newsPopup}>
                <span>
                  <span>
                    <SVG src="news"/>
                  </span>
                  {visibleNew.title}
                </span>
                <CloseButton className={classes.closeButton} onClick={() => handleMakeNewRead(visibleNew.popupID)}/>
                <div>
                  <span className={classes.date}>
                    <SVG src="clock"/>
                    {DateFormat(visibleNew.date, "timeline", languageData, t)}
                  </span>
                  <div className={classes.category}>
                    <span>{visibleNew.category}</span>
                  </div>
                </div>
                <Clamp lines={4}>
                  <p>{htmlParse(visibleNew.content)}</p>
                </Clamp>
              </div>
            ) : null
          }
        </div>
        <Sidebar/>
      </div>
    </div>
  );
};

export default PageTemplate;