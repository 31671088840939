import * as DirectorAbsenceService from '../../../../services/director/absence.service';
import * as DirectorAttendanceService from '../../../../services/director/attendance.service';
import * as DirectorCalendarService from '../../../../services/director/calendar.service';
import * as DirectorClassbookService from '../../../../services/director/classbook.service';
import * as DirectorClubsService from '../../../../services/director/clubs.service';
import * as DirectorCommentsService from '../../../../services/director/comments.service';
import * as DirectorCommunicationService from '../../../../services/director/communication.service';
import * as DirectorDataService from '../../../../services/director/data.service';
import * as DirectorExportService from '../../../../services/director/export.service';
import * as DirectorMealsService from '../../../../services/director/meals.service';
import * as DirectorPopupsService from '../../../../services/director/popups.service';
import * as DirectorReactionsService from '../../../../services/director/reactions.service';
import * as DirectorStockService from '../../../../services/director/stock.service';
import * as DirectorSubstitutionsService from '../../../../services/director/substitutions.service';
import * as DirectorTimelineService from '../../../../services/director/timeline.service';
import * as DirectorTimetableService from '../../../../services/director/timetable.service';
import * as DirectorWagesService from '../../../../services/director/wages.service';
import * as ParentAbsenceService from '../../../../services/parent/absence.service';
import * as ParentCalendarService from '../../../../services/parent/calendar.service';
import * as ParentClubsService from '../../../../services/parent/clubs.service';
import * as ParentCommentsService from '../../../../services/parent/comments.service';
import * as ParentCommunicationService from '../../../../services/director/communication.service';
import * as ParentDataService from '../../../../services/parent/data.service';
import * as ParentExportService from '../../../../services/parent/export.service';
import * as ParentGalleryService from '../../../../services/parent/gallery.service';
import * as ParentMealsService from '../../../../services/parent/meals.service';
import * as ParentOrderService from '../../../../services/parent/order.service';
import * as ParentPaymentService from '../../../../services/parent/payment.service';
import * as ParentPopupsService from '../../../../services/parent/popups.service';
import * as ParentReactionsService from '../../../../services/parent/reactions.service';
import * as ParentStockService from '../../../../services/parent/stock.service';
import * as ParentSubstitutionsService from '../../../../services/parent/substitutions.service';
import * as ParentTimelineService from '../../../../services/parent/timeline.service';
import * as ParentTimetableService from '../../../../services/parent/timetable.service';
import * as TeacherAbsenceService from '../../../../services/teacher/absence.service';
import * as TeacherAttendanceService from '../../../../services/teacher/attendance.service';
import * as TeacherCalendarService from '../../../../services/teacher/calendar.service';
import * as TeacherClassbookService from '../../../../services/teacher/classbook.service';
import * as TeacherClubsService from '../../../../services/teacher/clubs.service';
import * as TeacherCommentsService from '../../../../services/teacher/comments.service';
import * as TeacherCommunicationService from '../../../../services/director/communication.service';
import * as TeacherDataService from '../../../../services/teacher/data.service';
import * as TeacherExportService from '../../../../services/teacher/export.service';
import * as TeacherMealsService from '../../../../services/teacher/meals.service';
import * as TeacherPopupsService from '../../../../services/teacher/popups.service';
import * as TeacherReactionsService from '../../../../services/teacher/reactions.service';
import * as TeacherSubstitutionsService from '../../../../services/teacher/substitutions.service';
import * as TeacherTimelineService from '../../../../services/teacher/timeline.service';
import * as TeacherTimetableService from '../../../../services/teacher/timetable.service';
import * as TeacherWagesService from '../../../../services/teacher/wages.service';
import { getUserRole } from 'src/utils/useUser';
import { setAbsenceService, setAttendanceService, setCalendarService, setClassbookService, setClubsService, setCommentsService, setCommunicationService, setDataService, setExportService, setGalleryService, setMealsService, setOrderService, setPaymentService, setPopupsService, setReactionsService, setStockService, setSubstitutionsService, setTimelineService, setTimetableService, setWagesService } from '../../../../store/actions/services.actions';

const setServices = (dispatch: any, userData: any) => {
  if(getUserRole(userData.userObject.roleType) === "parent") {
    dispatch(setAbsenceService(ParentAbsenceService));
    dispatch(setCalendarService(ParentCalendarService));
    dispatch(setClubsService(ParentClubsService));
    dispatch(setCommentsService(ParentCommentsService));
    dispatch(setCommunicationService(ParentCommunicationService));
    dispatch(setDataService(ParentDataService));
    dispatch(setExportService(ParentExportService));
    dispatch(setGalleryService(ParentGalleryService));
    dispatch(setMealsService(ParentMealsService));
    dispatch(setOrderService(ParentOrderService));
    dispatch(setPaymentService(ParentPaymentService));
    dispatch(setPopupsService(ParentPopupsService));
    dispatch(setReactionsService(ParentReactionsService));
    dispatch(setStockService(ParentStockService));
    dispatch(setSubstitutionsService(ParentSubstitutionsService));
    dispatch(setTimelineService(ParentTimelineService));
    dispatch(setTimetableService(ParentTimetableService));
  } else if(getUserRole(userData.userObject.roleType) === "teacher") {
    dispatch(setAbsenceService(TeacherAbsenceService));
    dispatch(setAttendanceService(TeacherAttendanceService));
    dispatch(setCalendarService(TeacherCalendarService));
    dispatch(setClassbookService(TeacherClassbookService));
    dispatch(setClubsService(TeacherClubsService));
    dispatch(setCommentsService(TeacherCommentsService));
    dispatch(setCommunicationService(TeacherCommunicationService));
    dispatch(setDataService(TeacherDataService));
    dispatch(setExportService(TeacherExportService));
    dispatch(setMealsService(TeacherMealsService));
    dispatch(setPopupsService(TeacherPopupsService));
    dispatch(setReactionsService(TeacherReactionsService));
    dispatch(setSubstitutionsService(TeacherSubstitutionsService));
    dispatch(setTimelineService(TeacherTimelineService));  
    dispatch(setTimetableService(TeacherTimetableService));
    dispatch(setWagesService(TeacherWagesService));
  } else if(getUserRole(userData.userObject.roleType) === "director") {
    dispatch(setAbsenceService(DirectorAbsenceService));
    dispatch(setAttendanceService(DirectorAttendanceService));
    dispatch(setCalendarService(DirectorCalendarService));
    dispatch(setClassbookService(DirectorClassbookService));
    dispatch(setClubsService(DirectorClubsService));
    dispatch(setCommentsService(DirectorCommentsService));
    dispatch(setCommunicationService(DirectorCommunicationService));
    dispatch(setDataService(DirectorDataService));
    dispatch(setExportService(DirectorExportService));
    dispatch(setMealsService(DirectorMealsService));
    dispatch(setPopupsService(DirectorPopupsService));
    dispatch(setReactionsService(DirectorReactionsService));
    dispatch(setStockService(DirectorStockService));
    dispatch(setSubstitutionsService(DirectorSubstitutionsService));
    dispatch(setTimelineService(DirectorTimelineService));  
    dispatch(setTimetableService(DirectorTimetableService));
    dispatch(setWagesService(DirectorWagesService));
  } else {
    return false;
  }
  return true;
};

export default setServices;