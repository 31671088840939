import loadChildren from '../modules/Children';
import loadClasses from '../modules/Classes';
import loadConfiguration from '../modules/Configuration';
import loadEmployees from '../modules/Employees';
import loadOpenedModals from '../modules/OpenedModals';
import loadPopups from '../modules/Popups';
import loadPremium from '../modules/Premium';
import loadSchools from '../modules/Schools';
import loadTracking from '../modules/Tracking';
import loadUser from '../modules/User';
import loadUsers from '../modules/Users';
import loadUserSettings from '../modules/UserSettings';
import resetAllData from '../modules/ResetAllData';
import setServices from '../modules/Services';
import validToken from '../modules/ValidToken';

const statuses = [
  "notStarted",
  "inProgress",
  "isFailed",
  "isDone",
];

const modules = [
  {
    name: "resetAllData",
    status: statuses[0],
    dependencies: [],
    data: null,
    func: resetAllData,
    funcData: ["dispatch"],
  },
  {
    name: "validToken",
    status: statuses[0],
    dependencies: [],
    data: null,
    func: validToken,
    funcData: ["dispatch"],
  },
  {
    name: "user",
    status: statuses[0],
    dependencies: ["validToken"],
    data: null,
    func: loadUser,
    funcData: ["dispatch"],
  },
  {
    name: "userSettings",
    status: statuses[0],
    dependencies: ["validToken", "user"],
    data: null,
    func: loadUserSettings,
    funcData: ["dispatch", "userData"],
  },
  {
    name: "configuration",
    status: statuses[0],
    dependencies: ["validToken", "user", "userSettings"],
    data: null,
    func: loadConfiguration,
    funcData: ["dispatch", "languageData"],
  },
  {
    name: "users",
    status: statuses[0],
    dependencies: ["validToken"],
    data: null,
    func: loadUsers,
    funcData: ["dispatch", "userData"],
  },
  {
    name: "employees",
    status: statuses[0],
    dependencies: ["validToken"],
    data: null,
    func: loadEmployees,
    funcData: ["dispatch", "userData"],
  },
  {
    name: "classes",
    status: statuses[0],
    dependencies: ["validToken", "user"],
    data: null,
    func: loadClasses,
    funcData: ["dispatch", "userData", "classesID"],
  },
  {
    name: "schools",
    status: statuses[0],
    dependencies: ["validToken", "user"],
    data: null,
    func: loadSchools,
    funcData: ["dispatch", "userData", "schoolsID"],
  },
  {
    name: "children",
    status: statuses[0],
    dependencies: ["validToken", "user", "classes"],
    data: null,
    func: loadChildren,
    funcData: ["dispatch", "userData", "classes"],
  },
  {
    name: "premium",
    status: statuses[0],
    dependencies: ["validToken"],
    data: null,
    func: loadPremium,
    funcData: ["dispatch", "userData"],
  },
  {
    name: "services",
    status: statuses[0],
    dependencies: ["validToken"],
    data: null,
    func: setServices,
    funcData: ["dispatch", "userData"],
  },
  {
    name: "openedModals",
    status: statuses[0],
    dependencies: ["validToken"],
    data: null,
    func: loadOpenedModals,
    funcData: ["dispatch", "userData"],
  },
  {
    name: "tracking",
    status: statuses[0],
    dependencies: ["validToken", "user"],
    data: null,
    func: loadTracking,
    funcData: ["userData", "languageData", "windowHandler", "location"],
  },
  {
    name: "popups",
    status: statuses[0],
    dependencies: ["validToken"],
    data: null,
    func: loadPopups,
    funcData: ["dispatch", "userData"],
  }
];

export {
  statuses,
  modules
};